@font-face {
  font-family: "Ohno Blazeface";
  src: url("./fonts/OhnoBlazeface-12Point.woff2") format("woff2"),
    url("./fonts/OhnoBlazeface-12Point.woff") format("woff");
  font-weight: 100;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Degular";
  src: url("./fonts/Degular-Regular.woff2") format("woff2"),
    url("./fonts/Degular-Regular.woff") format("woff");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

* {
  padding: 0px;
  margin: 0px;
  outline: none;
  text-decoration: none;
  box-sizing: border-box;
  font-family: "Degular";
  font-weight: normal;
}

html,
body {
  width: 100%;
  height: 100%;
  background-color: #fbf7f4;
}

strong {
  font-weight: 700;
}
